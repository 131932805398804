import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import styled from 'styled-components';
import '../../css/filterEshopList.css';

const FilterForm = ({ onSubmit }) => (
  <Card>
    <CardBody>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} className="filterForm">
            <Row>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Dominio</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="domain"
                      component="input"
                      type="text"
                      placeholder="Dominio"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Stato</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="status"
                      component={renderSelectField}
                      type='select'
                      options={[
                        { value: 'all', label: 'Tutti gli stati' },
                        { value: '0', label: 'In attesa' },
                        { value: '1', label: 'Approvati' },
                        { value: '2', label: 'Rifiutati' },
                      ]}
                      placeholder="Tutti gli stati"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form>
    </CardBody>
  </Card>
);

FilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FilterForm;

const FilterButton = styled(Button)`
    margin-top: calc(1.5em + .35rem);
`;
