import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomReviewInvitations } from '../actions/fetchCustomReviewInvitations';

export const customReviewInvitationsSlice = createSlice({
    name: 'fetchCustomReviewInvitations',
    initialState: {
        items: [],
        loading: 'idle',
        pagination: {
            total_count: 0,
            page_count: 0,
            current_page: 0,
            per_page: 0,
            next_page_url: null,
            prev_page_url: null,
        }
    },
    reducers: {
        itemApproved: (state, action) => {
            const index = state.items.findIndex(item => item.modified.id === action.payload);
            if (index !== -1) {
                // Aggiorna lo stato di `modified.status` a 1 per l'elemento trovato
                state.items[index].status = 1; // Status per "approvato"
            }
        },
        itemRejected: (state, action) => {
            const index = state.items.findIndex(item => item.modified.id === action.payload);
            if (index !== -1) {
                state.items[index].status = 2; // Status per "rifiutato"
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomReviewInvitations.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(fetchCustomReviewInvitations.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.items = action.payload.items;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchCustomReviewInvitations.rejected, (state) => {
                state.loading = 'idle';
            });
    }

});

export const { itemApproved, itemRejected } = customReviewInvitationsSlice.actions;

export default customReviewInvitationsSlice.reducer;