import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FaKeyboard } from "react-icons/fa";

export const PartialReviewActions = ({ onAction, partialReviewId, item, isActive }) => {
    const { review_data } = item;
    const { title, description, detail_rating } = review_data;

    // Check conditions
    const hasValidRating = detail_rating && Object.keys(detail_rating).length > 0;
    const hasValidTitle = title && title.trim().length > 0;
    const hasValidDescription = description && description.trim().length > 0;

    // Generate auto-title
    const autoGeneratedTitle = description
        ? description.split(" ").slice(0, 3).join(" ")
        : "Auto Title";

    // Handle key presses
    const handleKeyPress = (event) => {
        if (!isActive) return; // Ensure the button is only active on the visible carousel slide
        console.log(event.key);
        switch (String(event.key)) {
            case "c":
                onAction(partialReviewId, "reject");
                break;
            case "b":
                onAction(partialReviewId, "doubt");
                break;
            case "a":
                if (hasValidRating && hasValidTitle && hasValidDescription){
                    onAction(partialReviewId, "validate");
                }
                break;
            case "s":
                if (hasValidRating && hasValidTitle){
                    onAction(partialReviewId, "approve_only_title");
                }
                break;
            case "d":
                if (hasValidRating){
                    onAction(partialReviewId, "approve_with_auto_title");
                }
                break;
            default:
                break;
        }
    };

    // Add keydown event listener
    React.useEffect(() => {
        if (!isActive) return;
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [hasValidRating, hasValidTitle, hasValidDescription, isActive]);

    return (
        <Row className="mt-4">
            <Col className="d-flex justify-content-center gap-2">
                {/* Validate Button */}
                {hasValidRating && hasValidTitle && hasValidDescription && (
                    <Button
                        className='px-4'
                        variant="success"
                        onClick={() => onAction(partialReviewId, "validate")}
                    >
                        Approva<br/><FaKeyboard className="ms-1" /> (A)
                    </Button>
                )}

                {/* Approve with Title Button */}
                {hasValidRating && hasValidTitle && (
                    <Button
                        variant="primary"
                        onClick={() => onAction(partialReviewId, "approve_only_title")}
                    >
                        Approva con solo Titolo<br /><FaKeyboard className="ms-1" /> (S)
                    </Button>
                )}

                {/* Approve with Auto Title Button */}
                {hasValidRating && (
                    <Button
                        variant="info"
                        onClick={() =>
                            onAction(partialReviewId, "approve_with_auto_title", autoGeneratedTitle)
                        }
                    >
                        Approva con Auto Titolo<br /><FaKeyboard className="ms-1" /> (D)
                    </Button>
                )}

                {/* Always include Reject Button */}
                <Button
                    className='px-4'
                    variant="warning"
                    onClick={() => onAction(partialReviewId, "doubt")}
                >
                    ? <br /><FaKeyboard className="ms-1" /> (B)
                </Button>
                <Button
                    className='px-4'
                    variant="danger"
                    onClick={() => onAction(partialReviewId, "reject")}
                >
                    Rifiuta<br /><FaKeyboard className="ms-1" /> (C)
                </Button>
            </Col>
        </Row>
    );
};
