import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '@/config/api';
import SidebarMenu from '@/containers/Layout/custom_components/SidebarMenu'
import sidebar_links from './configs/sidebar_eshops';
import endpoint_eshop from './endpoints/eshops';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@/containers/Components/Breadcrumbs';
import EshopData from './forms/eshopData';
import EshopCategories from './forms/eshopCategories';
import EshopBillingData from './forms/eshopBillingData';
import EshopBookkeepingHistory from './forms/eshopBookkeepingHistory';
import EshopNotes from './forms/eshopNotes';
import EshopLogo from './forms/eshopLogo';
import EshopThumb from './forms/eshopThumb';
import EshopGallery from './forms/eshopGallery';
import EshopPlans from './forms/eshopPlans';
import EshopAccessLogs from './eshopAccessLogs';
import EshopPayments from './forms/eshopPayments';
import EshopInvitations from './forms/eshopInvitations';
import EshopReviews from './forms/eshopReviews';
import EshopCoupons from './forms/eshopCoupons';
import EshopImportReviews from './forms/eshopImportReviews';
import EshopAnalysis from './forms/eshopAnalysis';
import EshopOrders from './forms/eshopOrders';
import EshopReviewsApi from './forms/eshopReviewsApi';
import EshopImportProducts from './forms/eshopImportProducts';
import EshopRatingConfig from './forms/eshopRatingConfig';

const Update = () => {

  const { id } = useParams();
  const [currentSection, setCurrentSection] = useState('eshop-data');
  const [domainData, setDomainData] = useState({});
  const breadcrumbPaths = [
    { label: 'Home', url: '/', icon: faHome },
    { label: 'Eshops', url: '/registered-eshops/list', icon: false },
    { label: domainData.domain, url: false, icon: false },
  ];

  const getSelection = () => {
    switch (currentSection) {
      case 'eshop-data':
        return <EshopData item={domainData} />;
      case 'eshop-categories':
        return <EshopCategories esa_id={domainData.id} />;
      case 'bookkeeping-story':
        return <EshopBookkeepingHistory item={domainData} />;
      case 'billing-data':
        return <EshopBillingData item={domainData} />;
      case 'eshop-notes':
        return <EshopNotes item={domainData} />;
      case 'eshop-image':
        return <EshopLogo item={domainData} />;
      case 'eshop-thumb':
        return <EshopThumb item={domainData} />;
      case 'eshop-galleries':
        return <EshopGallery item={domainData} refreshData={fetchData} />;
      case 'eshop-plans':
        return <EshopPlans item={domainData} changePage={changePageClick} />;
      case 'eshop-payments':
        return <EshopPayments item={domainData} />;
      case 'eshop-access-logs':
        return <EshopAccessLogs item={domainData} />;
      case 'eshop-import-invitations':
        return <EshopInvitations esa_id={domainData.id} />;
      case 'eshop-reviews':
        return <EshopReviews esa_id={domainData.id} />;
      case 'product-reviews':
        return <EshopReviews esa_id={domainData.id} is_product={true} />;
      case 'eshop-coupons':
        return <EshopCoupons esa_id={domainData.id} />;
      case 'eshop-imported-reviews':
        return <EshopImportReviews esa_id={domainData.id} domain={domainData.domain} />;
      case 'eshop-invitation-stats':
        return <EshopOrders esa_id={domainData.id} />;
      case 'eshop-analysis':
        return <EshopAnalysis item={domainData} />;
      case 'eshop-extra-reviews':
        return <EshopReviewsApi item={domainData} />;
      case 'eshop-product-import':
        return <EshopImportProducts esa_id={domainData.id} />;
      case 'eshop-rating-config':
        return <EshopRatingConfig item={domainData} />;
      default:
        debugger
        return null;
    }
  }

  const changePageClick = (page) => {
    setCurrentSection(page);
  }

  const fetchData = async () => {
    const response = await api.get(endpoint_eshop.getEshop.replace(':id', id));
    setDomainData(response.data);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Container>
      <Row >
        <Col className='my-1'>
          <h2>
            <FontAwesomeIcon icon={faStoreAlt} className='me-3' />
            {domainData.domain}
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Breadcrumbs paths={breadcrumbPaths} />
        </Col>
      </Row>
      <Row>
        <SidebarMenu routes={sidebar_links} changePageClick={changePageClick} currentPage={currentSection} />
        <Col xs={12} lg={8} xl={9}>
          {getSelection()}
        </Col>
      </Row>
    </Container>
  )
}

export default Update;