import { PartialReviewRating } from './PartialReviewRating';
import { PartialReviewActions } from './PartialReviewAction';
import { PartialProductReviewAction } from './PartialProductReviewAction';
import { Card, Badge } from 'react-bootstrap';
import './../../styles/partialReview.css';
import { id } from 'ethers/lib/utils';

const getOverallRating = (detailRating) => {
    if (!detailRating) return 0;

    const total = Object.values(detailRating).reduce((acc, curr) => acc + parseFloat(curr), 0);
    const average = total / Object.keys(detailRating).length;
    return average;
};

const countWords = (str) => {
    if (!str || typeof str !== 'string') {
        return 0;
    }
    // Remove all non-word characters
    str = str.replace(/[^A-Za-z0-9\s]/g, '');
    return str.trim().split(/\s+/).length;
};

const completedProductReviews = (productReviews) => {
    if (!productReviews) return 0;
    return Object.values(productReviews).filter(
        (product) => product.rating && product.description
    ).length;
};
const completedPartiallyProductReviews = (productReviews) => {
    if (!productReviews) return 0;
    return Object.values(productReviews).filter(
        (product) => product.rating || product.description
    ).length;
};

export const PartialReviewCard = ({ idx, activeIndex, item, onAction, getAverageRating, removalAction }) => {
    const reviewDate = new Date(item.review_data.insert_date);
    const lastTrackDate = new Date(item.order.status_data.click_update_date);
    if (reviewDate < lastTrackDate) {
        reviewDate.setHours(reviewDate.getHours() + 1);
    }
    const isActive = idx === activeIndex;
    const totalProducts = item.order.products ? item.order.products.length : 0;
    let productButtonIndex = 1; // Start indexing from 1
    return (
        <Card className="mx-auto" style={{ maxWidth: '800px' }}>
            <Card.Body>
                    <div className="row w-100 mb-0">
                        <div className="col">
                            <small className="text-muted mt-2 mb-1">
                                <h6>Eshop:</h6>
                                <div className="d-flex align-items-center">
                                    <a href={`/registered-eshops/update/${item.order.eshop_id}`} target="_blank" rel="noopener noreferrer" className='fs-6'>
                                        {item.order.domain_info.domain}
                                    </a>
                                    <Badge bg="success" className="ms-2">
                                        {item.order.domain_info.plan}
                                    </Badge>
                                </div>
                            </small>
                        </div>
                        <div className="col">
                            <small className="text-muted mt-2 mb-1">
                                <h6>Email:</h6>
                                    {item.order.customer_email}
                            </small>
                        </div>
                        <div className="col p-0">
                            <small className="text-muted mt-2 mb-1">
                                <h6>Order-id:</h6>
                                <span className=''>
                                    {item.order.id}
                                </span>
                            </small>
                        </div>
                        <div className="col">
                            <small className="text-muted mt-2 mb-1">
                                <h6>Ultimo track:</h6>
                                <span className=''>
                                    {reviewDate.toLocaleString().replace(',', '')}
                                </span>
                            </small>
                        </div>
                        <div className="col">
                            <small className="text-muted mt-2 mb-1">
                                <h6>Ultimo apertura invito:</h6>
                                <span className=''>
                                    {lastTrackDate.toLocaleString().replace(',', '')}
                                </span>
                            </small>
                        </div>
                        {
                            item.review && (
                                <div className="col">
                                    <small className="text-muted mt-2 mb-1">
                                        <h6>Recensione parziale pubblicata il:</h6>
                                        <span className=''>
                                        {item.review.publication_date ? new Date(item.review.publication_date).toLocaleString().replace(',', '') 
                                        : "Data non disponibile"
                                        }
                                        </span>
                                    </small>
                                </div>
                            )
                        }
                    </div>

                    <Card.Title className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mt-3">
                        <div className="row w-100 mb-0">
                            <div className="col-md-6">
                                <span className="mb-0 mb-md-0">
                                    <blockquote className={`blockquote no-italic mb-0 ${!item.review_data.title && 'bq-danger'}`}>
                                        <h6>Titolo recensione:</h6>
                                        {item.review_data.title ? item.review_data.title : (
                                            <Badge bg="danger" text="white">
                                                Titolo recensione non inserito
                                            </Badge>
                                        )}
                                    </blockquote>
                                </span>
                                <div className="mb-3 fs-5 pt-2">
                                    <PartialReviewRating rating={getOverallRating(item.review_data.detail_rating)} />
                                    <Badge bg="primary" className="ms-2">
                                        {getAverageRating(item.review_data.detail_rating).toFixed(1)}
                                    </Badge>
                                    {
                                    item.review_data.detail_rating ? (
                                        <Badge
                                            bg={
                                                Object.keys(item.review_data.detail_rating).length >= 5
                                                    ? `success`
                                                    : `warning`
                                            }
                                            className="ms-2">
                                                Calcolato su {Object.keys(item.review_data.detail_rating).length} rating
                                            </Badge>
                                    ) : 
                                    (
                                        <Badge bg="danger" className="ms-2">
                                            Rating non inserito
                                        </Badge>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </Card.Title>

                

                <div className="mb-3">
                    <blockquote className={`blockquote no-italic ${!item.review_data.description && 'bq-danger'}`}>
                    <h6>Descrizione recensione:</h6>
                    <Card.Text>{item.review_data.description ? item.review_data.description : <Badge bg="danger" text="white">Descrizione recensione non inserita</Badge>}</Card.Text>
                    </blockquote>
                </div>

                <div className="container mb-3 mt-3">
                    <div className="row">
                        {item.review_data.detail_rating && (
                        <div className="col-md-6">
                            <h6 className='mb-2'><strong>Rating Inseriti:</strong></h6>
                            {Object.entries(item.review_data.detail_rating).map(([key, value]) => (
                                <div key={key} className="d-flex justify-content-between">
                                    <span>{key}:</span>
                                    <span><span className='fs-6'><strong>{value}</strong></span>/5</span>
                                </div>
                            ))}
                        </div>
                        )}
                        <div className="col-md-6">
                            <h6 className='mb-2'><strong>Paramentri recensione:</strong></h6>
                            <div className="d-flex flex-column">
                                {countWords(item.review_data.title) < 2 && <Badge bg="warning" className="mb-1 fs-6">Titolo di {countWords(item.review_data.title)} parol{countWords(item.review_data.title) == 1 ? 'a' : 'e'}</Badge>}
                                {countWords(item.review_data.description) < 5 && <Badge bg="danger" className="mb-1 fs-6">Descrizione di {countWords(item.review_data.description)} parol{countWords(item.review_data.description) == 1 ? 'a' : 'e'}</Badge>}
                                {
                                    item.review && (
                                        <Badge bg="danger" className="mb-1 fs-6">Una recensione parziale <br/>già e' stata pubblicata<br/> questo e' un aggiornamento</Badge>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <PartialReviewActions onAction={onAction} partialReviewId={item.id} item={item} isActive={isActive} />
                {item.order.products && (
                <div className="mt-4">
                    <h6 className="mb-2 fs-6">
                            Recensioni prodotto completate: <strong>{completedProductReviews(item.review_data.product_reviews)}/{totalProducts}</strong>
                    </h6>
                    {item.review_data.product_reviews &&
                    <>
                    {
                    totalProducts - completedProductReviews(item.review_data.product_reviews) > 0 && (
                                
                    <h6 className="mb-2 fs-6">
                                        Recensioni prodotto parziali: <strong>{completedPartiallyProductReviews(item.review_data.product_reviews) - completedProductReviews(item.review_data.product_reviews)}/{totalProducts}</strong>
                    </h6>
                    )
                    }
                    <div className="container mt-3">
                        {item.order.products.map((product) => {
                            const review = item.review_data.product_reviews[product.id];
                            // Skip if both rating and description are missing
                            if (!review?.rating && !review?.description) return null;

                            return (
                                <div key={product.id} className={`row mb-3 p-3 border rounded
                                ${
                                   isActive ? '' : 'd-none' // Hide non-active elements if needed
                                }
                                `}>
                                    <div className="col-md-2">
                                        <img
                                            src={product.img_prod}
                                            alt={product.name}
                                            className="img-fluid rounded custom-img-size"
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6>{product.name}</h6>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div>
                                                    <strong>Rating:</strong>{' '}
                                                    {review?.rating ? (
                                                        <>
                                                            <PartialReviewRating rating={review?.rating} />
                                                            <Badge bg="primary" className="ms-2">
                                                                {review.rating}
                                                            </Badge>
                                                        </>
                                                    ) : (
                                                        <Badge bg="danger fs-9">Rating mancante</Badge>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <strong>Descrizione:</strong>{' '}
                                                {review?.description ? (
                                                    review.description
                                                ) : (
                                                    <Badge bg="warning">Descrizione mancante</Badge>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-center justify-content-end">
                                        <PartialProductReviewAction
                                            review={review}
                                            indexValue={productButtonIndex}
                                            onApprove={(type) =>
                                                onAction(item.id, 'approve_product_review', {
                                                    productId: product.id,
                                                    type
                                                })
                                            }
                                            isActive={isActive}
                                        />
                                        {/* Increment the button index for the next action */}
                                        
                                        {review?.rating ? (productButtonIndex++ && null) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    </>
                    }
                </div>)}
            </Card.Body>
        </Card>
    );
};