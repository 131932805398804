import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filter';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchReviews } from './actions/fetchReviews';
import ReviewItemList from './components/ReviewItemList';
import PaginationTable from '@/containers/Components/PaginationTable';
import { TableCheckbox } from '@/shared/components/MaterialTableElements';
import { Button } from '@/shared/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { customSuccessNotify, customWarningNotify } from '../App/Router';
import { sendProofRequest } from './actions/sendProofRequest';

const ReviewsTable = ({ esa_id = false }) => {
  const dispatch = useDispatch();
  const reviewsState = useSelector(state => state.reviews.fetch || {});
  const reviews = reviewsState.entities;
  const loading = reviewsState.loading;
  const pagination = reviewsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const actionBaseUrl = '/v1/reviews'
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [toRefresh, setToRefresh] = useState(false);
  const tableLabels = esa_id
    ? ['Data e ora', 'Titolo', 'Utente', 'Voto', 'Stato', 'Media', 'Meta', '',]
    : ['Data e ora', 'Eshop', 'Piano', 'Titolo', 'Utente', 'Voto', 'Stato', 'Media', 'Meta', '',]

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;
    modifiedValues.creation_date = {
      start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
      end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
    }

    if (values.status) {
      modifiedValues.status = values.status.value;
    }

    if (values.partial_reviews) {
      modifiedValues.partial_reviews = values.partial_reviews.value;
    }

    if (values.newsletter) {
      modifiedValues.newsletter = values.newsletter.value;
    }

    setGlobalFilters(modifiedValues);

    // Aggiungi page e perPage ai valori modificati
    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25
    if (esa_id) {
      modifiedValues.eshop_id = esa_id;
    }

    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };

    dispatch(fetchReviews(payload));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  }

  const handleSendRequest = () => {
    const elements = (selectAll) ? reviews.map(elem => elem.review.id) : Object.keys(selectedItems).filter(key => selectedItems[key] === true)
    if (elements.length > 0) {
      const data = { ids: elements };
      dispatch(sendProofRequest({ data: data })).then(() => {
        dispatch(fetchReviews());
        customSuccessNotify('Richiesta inviata con successo');
      });
    }
    else {
      customWarningNotify('Seleziona almeno un elemento');
    }
  }

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    if (esa_id !== false) {
      const newGlobalFilters = { ...globalFilters, 'eshop_id': esa_id };
      setGlobalFilters(newGlobalFilters);

      const initialPayload = {
        baseUrl: actionBaseUrl,
        params: newGlobalFilters
      };

      dispatch(fetchReviews(initialPayload));
    } else {
      const initialPayload = {
        baseUrl: actionBaseUrl,
        params: globalFilters
      };

      dispatch(fetchReviews(initialPayload));
    }
  }, [dispatch, esa_id, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco recensioni</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} esa_id={esa_id} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={6}>
                    <p className="page-subtitle">Numero recensioni: {pagination.total_count}</p>
                  </Col>
                  <Col xs={12} lg={6} className='text-end'>
                    <Button variant={'outline-primary'} onClick={handleSendRequest}>
                      Richiedi P.acquisto
                      <FontAwesomeIcon icon={faFileAlt} className='ms-2' />
                    </Button>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        <th><TableCheckbox checked={selectAll} onChange={handleSelectAll} /></th>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {reviews && loading === 'idle' && reviews.length > 0 ? (
                        reviews.map((elem) => {
                          return elem.eshop ? (
                            <ReviewItemList
                              item={elem}
                              isSelected={selectAll}
                              handleSelected={setSelectedItems}
                              selectedList={selectedItems}
                              showEshop={esa_id == false ? true : false}
                              setToRefresh={setToRefresh}
                            />
                          ) : (console.log('NO ESHOP: ' + JSON.stringify(elem)))
                        })
                      ) : (
                        <tr >
                          <td colSpan={(tableLabels.length) + 1}>
                            Nessun elemento trovato
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchReviews} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ReviewsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default ReviewsTable;