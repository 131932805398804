import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const fetchReviewRatingModels = createAsyncThunk(
    'reviews/fetchReviewRatingModels',
    async (params = { 'per-page': 25, page: 1 }) => {
        const baseUrl = 'v1/review-ratings/fetch-ratings';

        // Generate a query string from 'params' and append it to 'baseUrl'
        const queryString = Object.entries(params)
            .map(([key, val]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
            })
            .join('&');

        const url = `${baseUrl}?${queryString}`;

        console.log('url: ', url);
        const response = await api.get(url);
        return response.data;
    }
);