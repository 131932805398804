import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';
import endpoints from '../endpoints/partialReviews.json';

export const fetchPartialReviews = createAsyncThunk(
    'partialReviews/fetchReviews',
    async (page = 1, { rejectWithValue }) => {
        try {
            const response = await api.get(`${endpoints.fetchPartialReviews}?page=${page}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

export const setPartialReviewStatus = createAsyncThunk(
    'partialReviews/setStatus',
    async ({ reviewId, actionType, data }, { rejectWithValue }) => {
        try {
            console.log(reviewId, actionType, data);
            const response = await api.put(`${endpoints.setPartialReview}/${reviewId}`,
                { 
                    "action_type": actionType ,
                    "data": data
                }
            );
            return { ...response };
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);