// partialReviewsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchPartialReviews, setPartialReviewStatus } from '../actions/actions';

const partialReviewsSlice = createSlice({
    name: 'partialReviews',
    initialState: {
        items: [],
        totalItems: 0,
        currentPage: 1,
        totalPages: 0,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle fetchPartialReviews states
            .addCase(fetchPartialReviews.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPartialReviews.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload.items;
                state.totalItems = action.payload.pagination.total;
                state.currentPage = action.payload.pagination.page;
                state.totalPages = action.payload.pagination.pageCount;
            })
            .addCase(fetchPartialReviews.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.items = [];
                state.total_items = 0;
            })
            // Handle setPartialReviewStatus states
            .addCase(setPartialReviewStatus.fulfilled, (state, action) => {
                const { reviewId, status } = action.payload;
                const review = state.items.find(item => item.id === reviewId);
                if (review) {
                    review.status = status;
                }
            })
            .addCase(setPartialReviewStatus.rejected, (state, action) => {
                state.error = action.payload;
            });
    }
});

export default partialReviewsSlice.reducer;