import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FilterForm from './components/filters/filter';
import { Col, Container, Row, Spinner, } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { cleanDate } from '@/shared/helpers';
import { colorAccent } from '@/utils/palette';
import { fetchProductReviews } from './actions/fetchProductReviews';
import ReviewItemList from './components/ReviewItemList';
import PaginationTable from '@/containers/Components/PaginationTable';

const ProductReviewsTable = ({ esa_id = false }) => {
  const dispatch = useDispatch();
  const reviewsState = useSelector(state => state.reviews.fetch || {});
  const reviews = reviewsState.entities;
  const loading = reviewsState.loading;
  const pagination = reviewsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState(esa_id ? { 'per-page': 25, page: 1, eshop_id: esa_id } : { 'per-page': 25, page: 1 });
  const actionBaseUrl = '/v1/product-reviews'
  const [toRefresh, setToRefresh] = useState(false);
  const tableLabels = esa_id
    ? ['Data e ora', 'ID Prodotto', 'Titolo', 'Utente', 'Voto', 'Stato', 'Media', 'Meta', '',]
    : ['Data e ora', 'Eshop', 'Piano', 'ID Prodotto', 'Titolo', 'Utente', 'Voto', 'Stato', 'Media', 'Meta', '',]

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    delete modifiedValues.creation_date_start;
    delete modifiedValues.creation_date_end;
    modifiedValues.creation_date = {
      start: values.creation_date_start ? cleanDate(values.creation_date_start) : null,
      end: values.creation_date_end ? cleanDate(values.creation_date_end) : null,
    }

    if (values.status) {
      modifiedValues.status = values.status.value;
    }
    if (values.newsletter) {
      modifiedValues.newsletter = values.newsletter.value;
    }

    setGlobalFilters(modifiedValues);

    // Aggiungi page e perPage ai valori modificati
    modifiedValues.page = pagination.current_page;
    if (esa_id) {
      modifiedValues.eshop_id = esa_id;
    }

    const payload = {
      baseUrl: actionBaseUrl,
      params: modifiedValues
    };

    dispatch(fetchProductReviews(payload));
  };

  useEffect(() => {
    if (toRefresh) {
      setToRefresh(false);
    }
    const initialPayload = {
      baseUrl: actionBaseUrl,
      params: globalFilters
    };

    dispatch(fetchProductReviews(initialPayload));
  }, [dispatch, esa_id, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Elenco recensioni prodotto</h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} isProduct={true} esa_id={esa_id} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row>
                  <Col md={12}>
                    <p className="page-subtitle">Numero recensioni: {pagination.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' ? (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              ) : (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {reviews && loading === 'idle' && reviews.length > 0 ? (
                        reviews.map((elem) => {
                          return elem.eshop ? (
                            <ReviewItemList
                              item={elem}
                              isProduct={true}
                              showEshop={esa_id == false ? true : false}
                              setToRefresh={setToRefresh}
                            />
                          ) : (console.log('NO ESHOP: ' + JSON.stringify(elem)))
                        })
                      ) : (
                        <tr >
                          <td colSpan={(tableLabels.length) + 1}>
                            Nessun elemento trovato
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchProductReviews} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ProductReviewsTable.propTypes = {
  onSubmit: PropTypes.func,
};

export default ProductReviewsTable;