import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from '../../../Eshops/list';
import Add from '../../../Eshops/add';
import Update from '../../../Eshops/update';
import InvitationList from '../../../Eshops/invitationList';
import PlanAnalysis from '../../../Eshops/planAnalysis';
import OrdersList from '../../../Eshops/ordersList';
import PaymentMethodsList from '@/containers/Eshops/paymentMethodsList';
import EshopAccessLogs from '@/containers/Eshops/eshopAccessLogs';
import customReviewInvitations from '@/containers/Eshops/customReviewInvitations';

export default () => (
  <Switch>
    <Route path="/registered-eshops/list" component={List} />
    <Route path="/registered-eshops/add" component={Add} />
    <Route path="/registered-eshops/update/:id" component={Update} />
    <Route path="/registered-eshops/invitation" component={InvitationList} />
    <Route path="/registered-eshops/ordersList" component={OrdersList} />
    <Route path="/registered-eshops/paymentMethods" component={PaymentMethodsList} />
    <Route path="/registered-eshops/access-logs" component={EshopAccessLogs} />
    <Route path="/registered-eshops/customReviewInvitationsList" component={customReviewInvitations} />
    <Route
      path="/registered-eshops/lite-analysis"
      render={(props) => <PlanAnalysis {...props} plan={'lite'} />}
    />
    <Route
      path="/registered-eshops/pro-analysis"
      render={(props) => <PlanAnalysis {...props} plan={'pro'} />}
    />
    <Route
      path="/registered-eshops/company-analysis"
      render={(props) => <PlanAnalysis {...props} plan={'company'} />}
    />
    <Route
      path="/registered-eshops/downgrade-analysis"
      render={(props) => <PlanAnalysis {...props} plan={'downgrade'} />}
    />
  </Switch>
);
