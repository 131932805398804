import React from 'react';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderCheckBoxField from '@/shared/components/form/CheckBox';

import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const FilterForm = ({ onSubmit }) => (
  <Card>
    <CardBody>
      < Form onSubmit={onSubmit} >
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} vertical>
            <Row className='g-2 w-100'>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Nome</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="title"
                      component="input"
                      type="text"
                      placeholder="Nome"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form >

    </CardBody>
  </Card>
);

export default FilterForm;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;

const ReceiptButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;