// components/ReviewCarousel/ReviewRating.js
import React from 'react';
import { StarFill } from 'react-bootstrap-icons';

export const PartialReviewRating = ({ rating }) => {
    return [...Array(5)].map((_, index) => (
        <StarFill
            key={index}
            className={`me-1 ${index < rating ? 'text-warning' : 'text-muted'}`}
        />
    ));
};