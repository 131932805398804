import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { colorAccent } from '@/utils/palette';
import FilterForm from './components/filters/filterReviewRatingModels';
import { fetchReviewRatingModels } from './actions/fetchReviewRatingModels';
import ReviewRatingModelItem from './components/ReviewRatingModelItem';
import PaginationTable from '@/containers/Components/PaginationTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createReviewRatingModel } from './actions/createReviewRatingModel';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import ReviewRatingModelModal from './components/modals/ReviewRatingModelModal';
import { warningNotify, successNotify } from '../App/Router';

const FetchReviewRatingModels = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const modelsState = useSelector(state => state.reviews.reviewRatingModels || {});
  const models = modelsState.items;
  const loading = modelsState.loading;
  const pagination = modelsState.pagination;
  const TableSpinner = styled(Spinner)`color: ${colorAccent};`;
  const [globalFilters, setGlobalFilters] = useState({ 'per-page': 25, page: 1 });
  const tableLabels = ['Nome', 'Valori di dettaglio', 'Valori opzionali', 'Numero recensioni', 'Numero eshops', '',]
  const [toRefresh, setToRefresh] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [resetFields, setResetFields] = useState(false);

  const handleCreateClick = () => {
    setShowCreateModal(true);
  };

  const triggerRefresh = () => {
    setToRefresh(prevState => !prevState);
  };

  const handleFilterSubmit = (values) => {
    let modifiedValues = { ...values };

    setGlobalFilters(modifiedValues);

    // Aggiungi page e perPage ai valori modificati
    modifiedValues.page = pagination.current_page;
    modifiedValues['per-page'] = 25

    dispatch(fetchReviewRatingModels(modifiedValues));
  };

  const handleModalClose = () => {
    setShowCreateModal(false);
  };

  const handleSave = (updatedElem) => {

    const data = {
      title: updatedElem.title,
      detail_ratings: updatedElem.detail_ratings
    }

    dispatch(createReviewRatingModel({ data: data })).then((response) => {
      if (response.payload.status == 'error') {
        warningNotify();
      } else {
        successNotify();
        setShowCreateModal(false);
        setResetFields(true);
        triggerRefresh();
      }
    });
  };

  useEffect(() => {

    dispatch(fetchReviewRatingModels(globalFilters));
  }, [dispatch, toRefresh]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Modelli rating
            <Button variant="success" onClick={() => handleCreateClick()} className='d-inline-block mx-3 my-2 p-1 float-end'>
              <FontAwesomeIcon icon={faPlusSquare} className='me-1 pe-1 border-end' />
              Aggiungi
            </Button>
          </h3>
        </Col>
      </Row>
      <FilterForm onSubmit={handleFilterSubmit} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <Row className='align-items-center'>
                  <Col xs={12} lg={6}>
                    <p className="page-subtitle">Numero modelli: {pagination?.total_count}</p>
                  </Col>
                </Row>
              </CardTitleWrap>
              {loading === 'loading' && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {models && loading === 'idle' && models.length > 0 && (
                <>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        {tableLabels.map((label) => {
                          return <th>{label}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {models.map((elem) => {
                        return <ReviewRatingModelItem item={elem} triggerRefresh={triggerRefresh} />
                      })}
                    </tbody>
                  </Table>
                  <PaginationTable pagination={pagination} filters={globalFilters} action={fetchReviewRatingModels} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ReviewRatingModelModal
        showModal={showCreateModal}
        handleClose={handleModalClose}
        handleSave={handleSave}
        reset={resetFields}
        item={false}
      />
    </Container>
  );
};

FetchReviewRatingModels.propTypes = {
  onSubmit: PropTypes.func,
};

export default FetchReviewRatingModels;