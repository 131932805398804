import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '@/config/api';

export const modApproved = createAsyncThunk(
    'customReviewInvitations/modApproved',
    async (itemId, { rejectWithValue }) => {
        try {
            const response = await api.put(`/v1/domains/update-custom-review-invitations/${itemId}`, {
                status: 1
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const modRejected = createAsyncThunk(
    'customReviewInvitations/modRejected',
    async (itemId, { rejectWithValue }) => {
        try {
            const response = await api.put(`/v1/domains/update-custom-review-invitations/${itemId}`, {
                status: 2
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
