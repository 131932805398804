import React from 'react';
import { Field, Form } from 'react-final-form';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderCheckBoxField from '@/shared/components/form/CheckBox';

import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import styled from 'styled-components';

const FilterForm = ({ onSubmit, isProduct = false, onlyValidation = false, esa_id = false }) => (
  <Card>
    <CardBody>
      < Form onSubmit={onSubmit} >
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit} vertical>
            <Row className='g-2 w-100'>
              {esa_id == false && (
                <Col>
                  <FormGroup>
                    <FormGroupLabel>Eshop</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="domain"
                        component="input"
                        type="text"
                        placeholder="Eshop"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              )}
              <Col>
                <FormGroup>
                  <FormGroupLabel>Email utente</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="user_email"
                      component="input"
                      type="email"
                      placeholder="example@mail.com"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormGroupLabel>Titolo</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="title"
                      component="input"
                      type="text"
                      placeholder="Titolo"
                    />
                  </FormGroupField>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <FormGroupLabel>Data inserimento</FormGroupLabel>
                  <FormGroupField>
                    <div className='d-inline-flex align-items-center'>
                      <Field
                        name="creation_date_start"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Dal"
                      />
                      <span className='mx-2'> - </span>
                      <Field
                        name="creation_date_end"
                        component={renderDatePickerField}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Al"
                      />
                    </div>
                  </FormGroupField>
                </FormGroup>
              </Col>
              {!onlyValidation && (
                <>
                  <Col>
                    <FormGroup>
                      <FormGroupLabel>Stato</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="status"
                          component={renderSelectField}
                          type="select"
                          options={[
                            { value: 'all', label: 'Tutte' },
                            { value: '0', label: 'Attesa validazione' },
                            { value: '1', label: 'Validata' },
                            { value: '2', label: 'Non validata' },
                            { value: '3', label: 'Attesa pubblicazione' },
                            { value: '4', label: 'Eliminata da utente' },
                            { value: '5', label: 'Sospesa' },
                          ]}
                          placeholder="Tutte"
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <FormGroupLabel>Complete/Parziali</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="partial_reviews"
                          component={renderSelectField}
                          type="select"
                          options={[
                            { value: 'all', label: 'Tutte' },
                            { value: '0', label: 'Solo complete' },
                            { value: '1', label: 'Solo Parziali' },
                          ]}
                          placeholder="Tutte"
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col>
                <FormGroup controlId="formButton">
                  <FilterButton
                    variant="outline-success"
                    size='sm'
                    onClick={handleSubmit}
                  >
                    <MagnifyIcon /><span>Filtra</span>
                  </FilterButton>
                </FormGroup>
              </Col>
            </Row>
            <Row className='g-2'>
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="esa_reviews"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Solo recensioni ESA"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              {!onlyValidation && (
                <Col xs={12} md={4} lg={2}>
                  <FormGroupField>
                    <Field
                      name="imported_reviews"
                      type="checkbox"
                      component={renderCheckBoxField}
                      label="Solo recensioni importate"
                      initialValue={false}
                      disabled={false}
                    />
                  </FormGroupField>
                </Col>
              )}
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="same_ip"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Solo recensioni stesso IP eshop"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              {!isProduct && (
                <>
                  {!onlyValidation && (
                    <Col xs={12} md={4} lg={2}>
                      <FormGroupField>
                        <Field
                          name="certified_reviews"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Solo recensioni verificate"
                          initialValue={false}
                          disabled={false}
                        />
                      </FormGroupField>
                    </Col>
                  )}
                  <Col xs={12} md={4} lg={2}>
                    <FormGroupField>
                      <Field
                        name="invitation_reviews"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Provenienti da invito"
                        initialValue={false}
                        disabled={false}
                      />
                    </FormGroupField>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <FormGroupField>
                      <Field
                        name="spontaneous_reviews"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Spontanee"
                        initialValue={false}
                        disabled={false}
                      />
                    </FormGroupField>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <FormGroupField>
                      <Field
                        name="receipt_reviews"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Con prova d'acquisto"
                        initialValue={false}
                        disabled={false}
                      />
                    </FormGroupField>
                  </Col>
                </>
              )}
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="registered_users"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Da utenti registrati"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="not_registered_users"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Da utenti NON registrati"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              {!isProduct && (
                <>
                  <Col xs={12} md={4} lg={2}>
                    <FormGroupField>
                      <Field
                        name="photos"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Foto"
                        initialValue={false}
                        disabled={false}
                      />
                    </FormGroupField>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <FormGroupField>
                      <Field
                        name="videos"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Video"
                        initialValue={false}
                        disabled={false}
                      />
                    </FormGroupField>
                  </Col>
                </>
              )}
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="waiting_optin"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Attesa optin utente"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              {!onlyValidation && (
                <Col xs={12} md={4} lg={2}>
                  <FormGroupField>
                    <Field
                      name="auto_validate"
                      type="checkbox"
                      component={renderCheckBoxField}
                      label="Auto validate"
                      initialValue={false}
                      disabled={false}
                    />
                  </FormGroupField>
                </Col>
              )}
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="negative_reviews"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="Negative (< 4.0)"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
              <Col xs={12} md={4} lg={2}>
                <FormGroupField>
                  <Field
                    name="positive_reviews"
                    type="checkbox"
                    component={renderCheckBoxField}
                    label="positive (>= 4.0)"
                    initialValue={false}
                    disabled={false}
                  />
                </FormGroupField>
              </Col>
            </Row>
          </FormContainer>
        )}
      </Form >

    </CardBody>
  </Card>
);

export default FilterForm;

const FilterButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;

const ReceiptButton = styled(Button)`
  margin-top: calc(1.5em + .35rem);
`;