import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FetchReviews from '../../../Reviews/fetchReviews';
import FetchProductReviews from '../../../Reviews/fetchProductReviews';
import CensoredWords from '../../../Reviews/censoredWords';
import NovalidationReasons from '../../../Reviews/novalidationReasons';
import ValidateReviews from '@/containers/Reviews/validateReviews';
import ValidateProductReviews from '@/containers/Reviews/validateProductReviews';
import ReviewsModified from '@/containers/Reviews/reviewsModified';
import EshopReplies from '@/containers/Reviews/eshopReplies';
import EshopPrivateReplies from '@/containers/Reviews/eshopPrivateReplies';
import ReportReviews from '@/containers/Reviews/reportReviews';
import ImportedSource from '@/containers/Reviews/importedSource';
import FetchReviewRatingModels from '@/containers/Reviews/fetchReviewRatingModels';
import PartialReviewsList from '../../../PartialReviews/list';

export default () => (
  <Switch>
    <Route path="/reviews/list" component={FetchReviews} />
    <Route path="/reviews/product-list" component={FetchProductReviews} />
    <Route path="/reviews/censored-words" component={CensoredWords} />
    <Route path="/reviews/novalidation-reasons" component={NovalidationReasons} />
    <Route path="/reviews/validate-reviews" component={ValidateReviews} />
    <Route path="/reviews/validate-product-reviews" component={ValidateProductReviews} />
    <Route path="/reviews/reviews-modified" component={ReviewsModified} />
    <Route path="/reviews/eshop-replies" component={EshopReplies} />
    <Route path="/reviews/eshop-private-replies" component={EshopPrivateReplies} />
    <Route path="/reviews/report-reviews" component={ReportReviews} />
    <Route path="/reviews/imported-source" component={ImportedSource} />
    <Route path="/reviews/fetch-review-rating-models" component={FetchReviewRatingModels} />
    <Route path="/reviews/partial-reviews" component={PartialReviewsList} />
  </Switch>
);
