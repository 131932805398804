import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../config/api';

export const deleteReviewRatingModel = createAsyncThunk(
    'review/deleteReviewRatingModel',
    async ({ id }) => {
        const url = `/v1/review-ratings/delete-rating/${id}`;
        const response = await api.delete(url);
        return response.data;
    }
);