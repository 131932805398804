import React, { useEffect, useState } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import { PartialReviewCard } from './PartialReviewCard';
import './../../styles/partialReview.css';

const PartialReviewCarousel = ({ reviews: incomingReviews, totalItems, onAction, onLoadMore, loading }) => {
    const [reviews, setReviews] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalItemsState, setTotalItemsState] = useState(totalItems || 0);

    // Synchronize reviews with incomingReviews
    useEffect(() => {
        setReviews((prevReviews) => {
            const newReviews = incomingReviews.filter(
                (review) => !prevReviews.some((prevReview) => prevReview.id === review.id)
            );
            return [...prevReviews, ...newReviews];
        });
    }, [incomingReviews]);

    // Update totalItemsState if totalItems prop changes
    useEffect(() => {
        if (totalItems !== undefined) {
            setTotalItemsState(totalItems);
        }
    }, [totalItems]);

    // Handle review removal
    const handleReviewRemoval = (reviewId) => {
        setReviews((prevReviews) => {
            const updatedReviews = prevReviews.filter((review) => review.id !== reviewId);

            // Adjust the active index if necessary
            if (activeIndex >= updatedReviews.length) {
                setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            }

            return updatedReviews;
        });

        // if there one review left, fetch more reviews
        if (reviews.length === 1) {
            onLoadMore();
        }

        // Decrement the total items count
        setTotalItemsState((prevTotal) => Math.max(prevTotal - 1, 0));
    };

    // Handle slide change
    const handleSlideChange = (selectedIndex) => {
        // Prevent going left from the first slide
        if (activeIndex === 0 && selectedIndex > activeIndex && selectedIndex !== (activeIndex + 1)) return;
        if (selectedIndex >= reviews.length) return; // Prevent invalid index
        console.log(selectedIndex);
        setActiveIndex(selectedIndex);

        // Only trigger the API call if explicitly navigating to the last slide
        if (selectedIndex === reviews.length - 1 && !loading) {
            onLoadMore();
        }
    };

    // Handle keyboard navigation
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            setActiveIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                if (nextIndex < reviews.length) {
                    if (nextIndex === reviews.length - 1 && !loading) {
                        onLoadMore();
                    }
                    return nextIndex;
                }
                return prevIndex;
            });
        } else if (event.key === 'ArrowLeft') {
            if (activeIndex === 0) return; // Prevent negative index
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        }
    };

    // Add and clean up the keydown event listener
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [reviews, activeIndex, loading]);

    // Calculate average rating
    const getAverageRating = (detailRating) => {
        if (!detailRating) return 0;
        const ratings = Object.values(detailRating).map(Number);
        return ratings.reduce((a, b) => a + b, 0) / ratings.length;
    };

    // Button click callback for removing a review
    const handleActionButtonClick = (itemId, actionType, data) => {
        if (['validate', 'reject', 'approve_only_title', 'approve_with_auto_title'].includes(actionType)) {
            handleReviewRemoval(itemId); // Remove the review
        }

        // if there less than 5 reviews, fetch more reviews
        onAction(itemId, actionType, data); // Trigger parent callback
    };

    return (
        <Container fluid className="p-3">
            {loading && (
                <div className="text-center mt-3">
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>{' '}
                    Loading more reviews...
                </div>
            )}
            <Carousel
                activeIndex={activeIndex}
                onSelect={handleSlideChange}
                interval={null}
                indicators={false}
                className="review-carousel"
                slide={false} // Disable slide animation to prevent conflicts
                nextIcon={<span aria-hidden="true" className="carousel-control-next-icon custom-carousel-icon" />}
                prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon custom-carousel-icon" />}
            >
                {reviews.map((item, index) => (
                    <Carousel.Item key={`${item.id}-${index}`}>
                        <div className="text-center mb-3">
                            <small className="text-muted">
                                Review {index + 1} of {totalItemsState}
                            </small>
                        </div>
                        <PartialReviewCard
                            idx={index}
                            activeIndex={activeIndex}
                            item={item}
                            onAction={handleActionButtonClick}
                            getAverageRating={getAverageRating}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    );
};

export default PartialReviewCarousel;
