import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

const ReviewRatingModelModal = ({ showModal, handleClose, item, handleSave, resetFields = false }) => {
  const [newItem, setNewItem] = useState({});
  const [detailRatings, setDetailRatings] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRatingChange = (id, field, value) => {
    setDetailRatings(prevRatings => ({
      ...prevRatings,
      [id]: {
        ...prevRatings[id],
        [field]: value
      }
    }));
    console.log(detailRatings);
  };

  const handleOptionalChange = (id, isChecked) => {
    setDetailRatings(prevRatings => ({
      ...prevRatings,
      [id]: {
        ...prevRatings[id],
        is_optional: isChecked ? '1' : '0'
      }
    }));
  };

  const addNewRatings = (count) => {
    const newRatings = {};
    for (let i = 0; i < count; i++) {
      const tempId = Date.now() + i; // Generate a unique ID
      newRatings[tempId] = { display_value: '', description: '', is_optional: '0' };
    }
    setDetailRatings(prevRatings => ({ ...prevRatings, ...newRatings }));
  };

  const removeRating = (id) => {
    setDetailRatings(prevRatings => {
      const newRatings = { ...prevRatings };
      delete newRatings[id];
      return newRatings;
    });
  };

  useEffect(() => {
    if (item) {
      setNewItem({
        title: item.title || ''
      });
      setDetailRatings(item.detail_ratings || {});
    }
  }, [item, resetFields]);

  const handleSubmit = () => {
    const updatedItem = {
      ...newItem,
      id: item.id,
      detail_ratings: detailRatings
    };
    handleSave(updatedItem);
  };


  return (
    <Modal show={showModal} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{`${item ? 'Modifica: ' + item.title : 'Aggiungi nuovo modello'}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form autoComplete="off">
            <Row className='align-items-end'>
              <Col xs={4} className='my-3'>
                <Form.Group controlId="title">
                  <Form.Label>Titolo</Form.Label>
                  <Form.Control
                    name="title"
                    value={newItem.title || ''}
                    type="text"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-end'>

              <Col xs={12} className='my-3'>
                <Form.Group controlId="detailRatings">
                  <Form.Label>Valori di dettaglio</Form.Label>
                  {Object.entries(detailRatings).map(([id, rating]) => (
                    <Form.Group controlId={`rating-${id}`} key={id} className="mb-3">
                      <Row>
                        <Col xs={7}>
                          <Form.Control
                            type="text"
                            value={rating.display_value || ''}
                            onChange={(e) => handleRatingChange(id, 'display_value', e.target.value)}
                            className="mb-2"
                          />
                        </Col>
                        <Col xs={3}>
                          <Form.Check
                            type="checkbox"
                            label="Valore opzionale"
                            checked={rating.is_optional === '1'}
                            onChange={(e) => handleOptionalChange(id, e.target.checked)}
                          />
                        </Col>
                        <Col xs={2}>
                          {!item && (
                            <Button variant="danger" size="sm" onClick={() => removeRating(id)}>
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          )}
                        </Col>
                        <Col xs={12}>
                          <Form.Control
                            as="textarea"
                            name="description"
                            placeholder="Inserisci descrizione"
                            rows="4"
                            value={rating.description || ''}
                            onChange={(e) => handleRatingChange(id, 'description', e.target.value)}
                          />
                        </Col>
                        <Col xs={12} className='mt-3'>
                          <hr />
                        </Col>
                      </Row>
                    </Form.Group>
                  ))}
                </Form.Group>
                {!item && (
                  <Button variant="primary" onClick={() => addNewRatings(1)}>
                    Aggiungi nuova riga
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} size="sm" variant={'success'} className="float-end">
          <FontAwesomeIcon icon={faSave} className="me-2" /> Salva
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReviewRatingModelModal;