import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import Badge from '@/shared/components/Badge';
import moment from 'moment';
import CustomReviewInvitationsModal from './modals/CustomReviewInvitationsModal';
import { itemApproved, itemRejected } from '../reducers/customReviewInvitations';
import { modApproved, modRejected } from '../actions/updateCustomReviewInvitations';
import { text } from '@fortawesome/fontawesome-svg-core';

function CustomReviewInvitationItem({ item }) {

  const dispatch = useDispatch();
  const insert_date = item.insert_date ? moment(item.insert_date).format('DD/MM/YYYY HH:mm:ss') : '-';
  const validation_date = item.validation_date ? moment(item.validation_date).format('DD/MM/YYYY HH:mm:ss') : '-';
  const [modalShow, setModalShow] = useState(false);
  const [selectedElem, setSelectedElem] = useState(null);
  const [visibleDropdownId, setVisibleDropdownId] = useState(null);

  const handleShow = (elem) => {
    setSelectedElem(elem);
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const toggleDropdown = (id) => {
    setVisibleDropdownId(visibleDropdownId === id ? null : id);
  };

  const handleApprove = (id) => {
    dispatch(itemApproved(id));
    dispatch(modApproved(id));
    setVisibleDropdownId(null);
  };

  const handleReject = (id) => {
    dispatch(itemRejected(id));
    dispatch(modRejected(id));
    setVisibleDropdownId(null);
  };

  const statusBadge = (status, id) => {
    switch (status) {
      case 0:
        return (
          <DropdownButton
            id={`dropdown-button-drop-${id}`}
            key={id}
            size="sm"
            variant="warning"
            title="In attesa di validazione"
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown(id);
            }}
          >
            <Dropdown.Item eventKey="1" onClick={() => handleApprove(id)}>Approva</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => handleReject(id)}>Rifiuta</Dropdown.Item>
          </DropdownButton>
        );
      case 1:
        return <Badge bg="success">Modifiche validate</Badge>;
      case 2:
        return <Badge bg="danger">Modifiche rifiutate</Badge>;
      default:
        return null;
    }
  };

  return (
    <>
      <tr key={item.id}>
        <td>
          <Link to={(item.plan == "ibrido") ? '/hibrid-eshops/update/' : '/registered-eshops/update/' + item.eshop_id} target='_blank'>
            {item.domain}
          </Link>
        </td>
        <td>
          <Badge bg={'success'} >{item.plan}</Badge>
        </td>
        <td className="first-letter-uppercase">{insert_date}</td>
        <td className="first-letter-uppercase">{validation_date}</td>
        <td className="first-letter-uppercase">
          <Button variant="primary" onClick={() => handleShow(item)} size={'sm'}>Anteprima</Button>
        </td>
        <td className="first-letter-uppercase">
          {statusBadge(item.status, item.id)}
        </td>
      </tr>
      {
        modalShow && (
          <CustomReviewInvitationsModal
            show={modalShow}
            handleClose={handleClose}
            item={selectedElem}
          />
        )
      }
    </>
  );
}

export default CustomReviewInvitationItem;