import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

function CustomReviewInvitationsModal({ item, show, handleClose }) {

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col xs={12} className='border-bottom'>
              <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default CustomReviewInvitationsModal;
